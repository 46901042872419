import { useState } from "react";
import emailjs from "emailjs-com";
import { Footer } from "./Footer";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Header2 } from "./Header2";

export const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    comments: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, email, subject, comments } = formData;

    if (!name || !email || !subject || !comments) {
      toast.error("Please fill out all fields.");
      return;
    }

    // Log the form data to the console
    console.log("Form Data:", formData);

    // Replace these placeholders with your actual EmailJS service ID, template ID, and user ID
    emailjs
      .send(
        "Nexient Ai",
        "template_pbyhih3",
        {
          to_name: "Nex",
          from_name: formData.name,
          message: `Subject: ${formData.subject}\n\nMessage: ${formData.comments}`,
          to_email: "hemant@nexientai.com",
        },
        "DWMAadL4qvINOVFzH"
      )
      .then(
        (result) => {
          // Show success message
          toast.success("Thanks! Your enquiry has been received!");
        },
        (error) => {
          // Handle error if email sending fails
          toast.error(
            "There was an error sending your message. Please try again."
          );
        }
      );
  };

  return (
    <>
      <Header2 />
      <div>
        {/* Start Hero */}
        <section className="relative table w-full py-36 bg-[url('../../assets/images/company/aboutus.html')] bg-center bg-no-repeat bg-cover">
          <div className="absolute inset-0 bg-black opacity-75"></div>
          <div className="container relative">
            <div className="grid grid-cols-1 pb-8 text-center mt-10">
              <h3 className="md:text-4xl text-3xl md:leading-normal tracking-wide leading-normal font-medium text-white">
                Contact Us
              </h3>
            </div>
          </div>

          <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
            <ul className="tracking-[0.5px] mb-0 inline-block">
              <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
                <a href="/">Nexient Ai</a>
              </li>
              <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
                <i className="uil uil-angle-right-b"></i>
              </li>
              <li
                className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white"
                aria-current="page"
              >
                Contact Us
              </li>
            </ul>
          </div>
        </section>
        <div className="relative">
          <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
            <svg
              className="w-full h-auto scale-[2.0] origin-top"
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        {/* End Hero */}

        {/* Start Section*/}
        <section className="relative md:py-24 py-16">
          <div className="container relative">
            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
              <div className="text-center px-6 mt-6">
                <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                  <i className="uil uil-phone"></i>
                </div>

                <div className="content mt-7">
                  <h5 className="title h5 text-xl font-medium">Phone</h5>
                  <p className="text-slate-400 mt-3">
                    Let's talk! Reach Us Anytime
                  </p>
                  <div className="mt-5">
                    <a
                      href="tel:+91 876 742 8583"
                      className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                    >
                      +91 876 742 8583
                    </a>
                  </div>
                </div>
              </div>

              <div className="text-center px-6 mt-6">
                <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                  <i className="uil uil-envelope"></i>
                </div>

                <div className="content mt-7">
                  <h5 className="title h5 text-xl font-medium">Email</h5>
                  <p className="text-slate-400 mt-3">Shoot Us an Email!</p>
                  <div className="mt-5">
                    <a
                      href="mailto:info@nexientai.com"
                      className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                    >
                      info@nexientai.com
                    </a>
                  </div>
                </div>
              </div>

              <div className="text-center px-6 mt-6">
                <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                  <i className="uil uil-map-marker"></i>
                </div>

                <div className="content mt-7">
                  <h5 className="title h5 text-xl font-medium">Location</h5>
                  <p className="text-slate-400 mt-3">
                    202, Daksha Impiria, <br /> Nashik, Maharashtra, India,
                    422009
                  </p>
                  <div className="mt-5">
                    {/* <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                    data-type="iframe" className="video-play-icon read-more lightbox relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">View on Google map</a>
              */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container relative md:mt-24 mt-16">
            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
              <div className="lg:col-span-7 md:col-span-6">
                <img src="assets/images/contact.svg" alt="Contact" />
              </div>

              <div className="lg:col-span-5 md:col-span-6">
                <div className="lg:ms-5">
                  <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                    <h3 className="mb-6 text-2xl leading-normal font-medium">
                      Get in touch !
                    </h3>

                    <form onSubmit={handleSubmit} id="myForm">
                      <p className="mb-0" id="error-msg"></p>
                      <div id="simple-msg"></div>
                      <div className="grid lg:grid-cols-12 lg:gap-6">
                        <div className="lg:col-span-6 mb-5">
                          <div className="text-start">
                            <label htmlFor="name" className="font-semibold">
                              Your Name:
                            </label>
                            <div className="form-icon relative mt-2">
                              <i
                                data-feather="user"
                                className="size-4 absolute top-3 start-4"
                              ></i>
                              <input
                                name="name"
                                id="name"
                                type="text"
                                value={formData.name}
                                onChange={handleChange}
                                className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                placeholder="Name :"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="lg:col-span-6 mb-5">
                          <div className="text-start">
                            <label htmlFor="email" className="font-semibold">
                              Your Email:
                            </label>
                            <div className="form-icon relative mt-2">
                              <i
                                data-feather="mail"
                                className="size-4 absolute top-3 start-4"
                              ></i>
                              <input
                                name="email"
                                id="email"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                placeholder="Email :"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-1">
                        <div className="mb-5">
                          <div className="text-start">
                            <label htmlFor="subject" className="font-semibold">
                              Your Question:
                            </label>
                            <div className="form-icon relative mt-2">
                              <i
                                data-feather="book"
                                className="size-4 absolute top-3 start-4"
                              ></i>
                              <input
                                name="subject"
                                id="subject"
                                value={formData.subject}
                                onChange={handleChange}
                                className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                placeholder="Subject :"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mb-5">
                          <div className="text-start">
                            <label htmlFor="comments" className="font-semibold">
                              Your Comment:
                            </label>
                            <div className="form-icon relative mt-2">
                              <i
                                data-feather="message-circle"
                                className="size-4 absolute top-3 start-4"
                              ></i>
                              <textarea
                                name="comments"
                                id="comments"
                                value={formData.comments}
                                onChange={handleChange}
                                className="form-input ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                placeholder="Message :"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        id="submit"
                        name="send"
                        className="py-2 px-5 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md justify-center flex items-center"
                      >
                        Send Message
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Section*/}
        <ToastContainer />
        <Footer />
      </div>
    </>
  );
};
