import React, { useState } from "react";
import Modal from "react-modal";
import emailjs from "emailjs-com";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Make sure to import CSS

// Set up the modal accessibility
Modal.setAppElement('#root');

export const Pricing = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "Enquiry",
    comments: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, subject, comments } = formData;

    if (!name || !email || !subject || !comments) {
      toast.error("Please fill out all fields.");
      return;
    }

    console.log("Form Data:", formData); // Log the form data

    emailjs
      .send(
        "Nexient Ai",
        "template_pbyhih3",
        {
          to_name: "Nex",
          from_name: formData.name,
          message: `Subject: ${formData.subject}\n\nMessage: ${formData.comments}`,
          to_email: "hemant@nexientai.com",
        },
        "DWMAadL4qvINOVFzH"
      )
      .then(
        (result) => {
          toast.success("Thanks! Your enquiry has been received!", {
            position: "top-right",
            autoClose: 5000, // 5 seconds
            style: { backgroundColor: '#4CAF50', color: '#fff' } // Green background
          });
          setIsModalOpen(false); // Close the modal on success
          setFormData({
            name: "",
            email: "",
            subject: "Enquiry",
            comments: "",
          });
        },
        (error) => {
          toast.error(
            "There was an error sending your message. Please try again."
          );
        }
      );
  };

  return (
    <section className="relative md:py-24 py-16" id="pricing">
      <div className="container relative">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
            Choose Pricing Plan
          </h3>
          <p className="text-slate-400 max-w-xl mx-auto">
            Start working with Techwind that can provide everything you need
            to generate awareness, drive traffic, connect.
          </p>
        </div>
        <div className="grid lg:grid-cols-2 mt-8 gap-[30px]">
          {/* Starter Plan */}
          <div className="group border-b-[3px] dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 duration-500 hover:scale-105 relative shadow dark:shadow-gray-800 rounded-md bg-white dark:bg-slate-900">
            <div className="p-6 py-8">
              <h6 className="font-bold uppercase mb-5 text-indigo-600">
                Starter
              </h6>
              <div className="flex mb-5">
                <span className="text-xl font-semibold">$</span>
                <span className="price text-4xl font-semibold mb-0">1250</span>
                <span className="text-xl font-semibold self-end mb-1">/yr</span>
              </div>
              <p className="text-slate-400 mb-4">0.007 per message</p>
              <ul className="list-none text-slate-400">
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2" />
                  On-Prem Deployment
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2" />
                  Complete Analytics
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2" />
                  Multi-Channel Support
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2" />
                  2 Chatbots
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2" />
                  Integration with Third-Party API
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2" />
                  Email Support
                </li>
              </ul>
              <button
                onClick={() => setIsModalOpen(true)}
                className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5"
              >
                Enquire Now
              </button>
            </div>
          </div>

          {/* Enterprise Plan */}
          <div className="group border-b-[3px] dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 duration-500 hover:scale-105 relative shadow dark:shadow-gray-800 rounded-md bg-white dark:bg-slate-900">
            <div className="p-6 py-8 md:ps-10">
              <h6 className="font-bold uppercase mb-5 text-indigo-600">
                Enterprise
              </h6>
              <div className="flex mb-5">
                <span className="text-xl font-semibold">$</span>
                <span className="price text-4xl font-semibold mb-0">3000</span>
                <span className="text-xl font-semibold self-end mb-1">&nbsp; ONE TIME</span>
              </div>
              <p className="text-slate-400 mb-4">0.007 per message</p>
              <ul className="list-none text-slate-400">
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2" />
                  On-Prem Deployment
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2" />
                  Human Handoff
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2" />
                  Complete Analytics
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2" />
                  Multi-Channel Support
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2" />
                  5 Chatbots
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2" />
                  Integration with Third-Party API
                </li>
                <li className="mb-1 flex">
                  <i className="uil uil-check-circle text-indigo-600 text-xl me-2" />
                  Live Call/Live Chat Support
                </li>
              </ul>
              <button
                onClick={() => setIsModalOpen(true)}
                className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5"
              >
                Enquire Now
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Enquiry Form */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            padding: "20px",
          },
        }}
      >
        <h2 className="text-xl font-bold mb-4">Enquiry Form</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-1">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              className="border border-gray-300 rounded w-full py-2 px-3"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              className="border border-gray-300 rounded w-full py-2 px-3"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1">Subject</label>
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
              required
              className="border border-gray-300 rounded w-full py-2 px-3"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1">Comments</label>
            <textarea
              name="comments"
              value={formData.comments}
              onChange={handleInputChange}
              required
              className="border border-gray-300 rounded w-full py-2 px-3"
              rows="4"
            />
          </div>
          <button
            type="submit"
            className="py-2 px-5 bg-indigo-600 text-white rounded hover:bg-indigo-700"
          >
            Submit
          </button>
        </form>
      </Modal>

      {/* Toast Container for Notifications */}
      <ToastContainer />
    </section>
  );
};
